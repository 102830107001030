import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosFetch from '../../helpers/axiosFetch';
import { RESOURCE_TYPES } from '../enums/resourceTypes';
import { METHOD_TYPES } from '../enums/methodTypes';

const initialState = {
    currencies: [],
    currenctCurrency: JSON.parse(localStorage.getItem('currency')) ?? {},
    loading: false,
    method: '',
    error: ''
};

export const fetchCurrencies = createAsyncThunk('fetchCurrencies', async ({ idulng }) => {
    const { data } = await axiosFetch.post(RESOURCE_TYPES.GET_CURRENCIES, null, {
        headers: {
            idulng
        }
    });
    return data;
});

export const currencySlice = createSlice({
    name: 'currency',
    initialState,
    reducers: {
        setCurrentCurrency(state, { payload }) {
            state.currenctCurrency = payload;
            localStorage.setItem('currency', JSON.stringify(payload));
        }
    },
    extraReducers: (builder) => {
        //! Fetch Currencies
        builder.addCase(fetchCurrencies.pending, (state) => {
            state.loading = true;
            state.method = METHOD_TYPES.READ;
            state.error = '';
        });
        builder.addCase(fetchCurrencies.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.currencies = payload;
        });
        builder.addCase(fetchCurrencies.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload.exception; // Error message
        });
    }
});

export const { setCurrentCurrency } = currencySlice.actions;
export default currencySlice.reducer;
