import React from 'react';
import Language from './Language';
import Currency from './Currency';

const LocalizationSection = () => (
    <div className="flex gap-2 sm:gap-0">
        <Language rigth={false} />
        <Currency />
    </div>
);

export default LocalizationSection;
