import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../layout/Loading';
import { facilityQRMenuGetDetail } from '../../store/slices/facilityMenuSlice';

const ProductDetail = ({ data, loading }) => {
    const dispatch = useDispatch();

    const [description, setDescription] = useState('');

    const facilityQrMenu = useSelector((state) => state.facilityQrMenu);
    const currenctLanguage = useSelector((state) => state.language.currenctLanguage);
    const currencysymbol = useSelector((state) => state.facilityQrMenu.currencysymbol);
    const currencysymbolcustomer = useSelector((state) => state.facilityQrMenu.currencysymbolcustomer);

    const fullmediaurl = `https://cdn.vevez.com/images/${data.mediaurl}_1920.jpg`;

    function addToBasket() {
        // Kullanıcı cihazını belirleme
        const userAgent = navigator.userAgent;
        const linkToAndroidApp = 'https://play.google.com/store/apps/details?id=com.vevez.vevezmobile';
        const linkToIOSApp = 'https://apps.apple.com/us/app/vevez/id6449850843';

        if (userAgent.match(/Android/i)) {
            // Kullanıcı Android'den girdi, Android uygulama indirme linkine yönlendir
            window.location.href = linkToAndroidApp;
        } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
            // Kullanıcı iOS'tan girdi, iOS uygulama indirme linkine yönlendir
            window.location.href = linkToIOSApp;
        } else {
            // Diğer platformlardan giriş yapıldı
        }
    }

    useEffect(() => {
        const idproductmenu = data.idproductmenu;
        const qr = facilityQrMenu.qr;
        const idlanguage = currenctLanguage.idlanguage;
        async function getProductDescription() {
            const productDetail = await dispatch(
                facilityQRMenuGetDetail({
                    qr,
                    idlanguage: idlanguage || 0,
                    idproductmenu
                })
            );
            console.log('productDetail.payload.data', productDetail.payload.data);
            setDescription(productDetail.payload.data.description ?? '');
        }
        if (idproductmenu && qr && idlanguage) {
            getProductDescription();
        }
    }, [data.idproductmenu, facilityQrMenu.qr, currenctLanguage.idlanguage]);

    return (
        <div>
            {loading && <Loading />}

            <div>
                <div className="w-full">
                    <img src={fullmediaurl} alt={data.name} className="w-full h-[50vh] object-cover" />
                </div>
                <div className="p-4">
                    <h2 className="font-semibold">{facilityQrMenu.formdata['WebMenu.Name'] ?? 'Name'}</h2>
                    <p>{data.name}</p>
                </div>
                <div className="p-4">
                    <h2 className="font-semibold">{facilityQrMenu.formdata['WebMenu.Description'] ?? 'Description'}</h2>
                    <p>{description}</p>
                </div>
                <div className="p-4">
                    <h2 className="font-semibold">{facilityQrMenu.formdata['WebMenu.Price'] ?? 'Price'}</h2>

                    <strong>
                        {data.price?.toFixed(2)} {currencysymbol}
                    </strong>
                    <div className="text-xs">
                        ( {data.pricecustomer?.toFixed(2)} {currencysymbolcustomer} )
                    </div>
                </div>
                <div className="p-4">
                    <button className="bg-red-500 w-full p-3 rounded-full text-white font-bold" type="button" onClick={() => addToBasket()}>
                        {facilityQrMenu.formdata['WebMenu.Order'] ?? 'Add to Basket'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProductDetail;
