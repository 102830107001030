import { Menu } from '@headlessui/react';
import React from 'react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const DropdownItem = ({ data, setValue }) => (
    <Menu.Item>
        {({ active }) => (
            <button
                type="button"
                className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}
                onClick={() => setValue(data)}
            >
                {data.name}
            </button>
        )}
    </Menu.Item>
);

export default DropdownItem;
