import { combineReducers } from '@reduxjs/toolkit';
import facilityMenuSlice from './slices/facilityMenuSlice';
import languageSlice from './slices/LanguageSlice';
import currencySlice from './slices/CurrencySlice';

const reducer = combineReducers({
    facilityQrMenu: facilityMenuSlice,
    language: languageSlice,
    currency: currencySlice
});

export default reducer;
