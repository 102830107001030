import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RESOURCE_TYPES } from '../enums/resourceTypes';
import axiosFetch from '../../helpers/axiosFetch';
import { METHOD_TYPES } from '../enums/methodTypes';

const initialState = {
    languages: [],
    currenctLanguage: JSON.parse(localStorage.getItem('language')) || {},
    loading: false,
    error: '',
    method: ''
};

export const fetchLanguages = createAsyncThunk('fetchLanguages', async () => {
    const { data } = await axiosFetch.post(RESOURCE_TYPES.GET_LANGUAGE_LIST, null, {
        headers: {
            idulng: 0
        }
    });

    return data;
});

export const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setCurrentLanguage(state, { payload }) {
            state.currenctLanguage = payload;
            localStorage.setItem('language', JSON.stringify(payload));
        }
    },
    extraReducers: (builder) => {
        //! Fetch Languages
        builder.addCase(fetchLanguages.pending, (state) => {
            state.loading = true;
            state.error = '';
            state.method = METHOD_TYPES.READ;
        });
        builder.addCase(fetchLanguages.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.languages = payload;
        });
        builder.addCase(fetchLanguages.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload.exception; // Error message
        });
    }
});

export const { setCurrentLanguage } = languageSlice.actions;
export default languageSlice.reducer;
