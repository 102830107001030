import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setQr } from '../../store/slices/facilityMenuSlice';
import ProductDetail from './ProductDetail';

export default function Product() {
    const dispatch = useDispatch();
    const qr = useSelector((state) => state.facilityQrMenu.qr);
    const products = useSelector((state) => state.facilityQrMenu.products);
    const productLoading = useSelector((state) => state.facilityQrMenu.loading);
    const currenctLanguage = useSelector((state) => state.language.currenctLanguage);
    const currenctCurrency = useSelector((state) => state.currency.currenctCurrency);

    const [product, setProduct] = useState({});
    const { idproductmenu, qrCode } = useParams();

    useEffect(() => {
        if (!qr) {
            dispatch(setQr(qrCode));
        }
    }, [qrCode, currenctLanguage, currenctCurrency]);

    useEffect(() => {
        if (products.length > 0) {
            const productFind = products.find((item) => item.idproductmenu === idproductmenu);
            if (productFind) {
                setProduct(products.find((item) => item.idproductmenu === idproductmenu));
            } else {
                throw new Error('Product not found!');
            }
        }
    }, [products]);

    return <ProductDetail data={product} loading={productLoading} />;
}
