import { Outlet } from 'react-router-dom';
import Header from '../layout/Header';

export default function Root() {
    return (
        <div className="min-h-full">
            <Header />
            <main>
                {/* <Breadcrumbs /> */}
                <div className="mx-auto">
                    <Outlet />
                </div>
            </main>
        </div>
    );
}
