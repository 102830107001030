import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IoIosArrowBack } from 'react-icons/io';
import LocalizationSection from './LocalizationSection';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function Header() {
    const location = useLocation();
    const facilityName = useSelector((state) => state.facilityQrMenu.name);
    const facilityCategories = useSelector((state) => state.facilityQrMenu.categories);
    const Qr = useSelector((state) => state.facilityQrMenu.qr);
    const navigate = useNavigate();

    return (
        <header className="sticky top-0">
            <div className="flex flex-wrap items-center justify-between bg-red-500 shadow py-4">
                {/* Facility */}
                <div className="flex items-center gap-2 ml-4 ">
                    {location.pathname !== `/${Qr}` && (
                        <IoIosArrowBack onClick={() => navigate(`/${Qr}`)} color="white" size="25" className="cursor-pointer" />
                    )}
                    <h1 className="text-xl font-bold tracking-tight text-white">{facilityName}</h1>
                </div>
                {/* Localization */}
                <div className="ml-auto mt-4">
                    <LocalizationSection />
                </div>
            </div>

            {/* Categories */}
            {!location.pathname.split('/product/')[1] && (
                <div className="flex  bg-gray-700 overflow-x-auto no-scrollbar pb-[.1rem]">
                    {facilityCategories.map((item) => (
                        <a
                            className={classNames(
                                location.hash.replace('#', '').replace(/%20/g, ' ').toLowerCase() === item.toLocaleLowerCase()
                                    ? 'text-yellow-500 border-yellow-500'
                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                'whitespace-nowrap text-sm px-4 py-3 border-b-2 border-gray-700'
                            )}
                            key={item}
                            href={`#${item}`}
                            aria-current={item}
                        >
                            {item}
                        </a>
                    ))}
                </div>
            )}
        </header>
    );
}
