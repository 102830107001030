import React from 'react';
import { Link } from 'react-router-dom';

const Product = ({ data, currencysymbol, currencysymbolcustomer }) => (
    <Link
        to={`product/${data.idproductmenu}`}
        key={data.idproductmenu}
        className="flex justify-between border-2 bg-white gap-1 rounded"
        id={data.name}
    >
        <div className="w-[40%]">
            <img
                src={`https://cdn.vevez.com/images/${data.mediaurl}_240.jpg`}
                alt={data.name}
                className="rounded-l w-full h-full object-cover"
            />
        </div>
        <div className="flex w-[60%] flex-col justify-between flex-auto mt-2">
            <div>
                <div className="font-medium text-gray-500">{data.name}</div>
            </div>
            <div className="m-2 text-right">
                <strong>
                    {data.price.toFixed(2)} {currencysymbol}
                </strong>
                <div className="text-sm">
                    ( {data.pricecustomer.toFixed(2)} {currencysymbolcustomer} )
                </div>
            </div>
        </div>
    </Link>
);

export default Product;
