import React, { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import DropdownItem from './DropdownItem';

const DropdownSearch = ({ data, value, setValue, rigth = true }) => {
    const [searchData, setSearchData] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
        function searchData(searchTerm, data) {
            searchTerm = searchTerm?.toLowerCase(); // Arama terimini küçük harfe çevir

            // Filtreleme işlemi
            const filteredData = data?.filter((item) => item?.name?.toLowerCase()?.includes(searchTerm));

            return filteredData;
        }

        if (data) {
            let newData = data;
            if (searchInput.length >= 2) {
                newData = searchData(searchInput, data);
            }
            setSearchData(newData);
        }
    }, [data, searchInput]);

    return (
        <Menu as="div" className="relative inline-block text-lef mr-4">
            <div>
                <Menu.Button className="inline-flex w-full justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    <span>{value}</span>
                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className={`absolute ${
                        rigth ? 'right-0' : 'left-0'
                    } h-72 w-auto overflow-y-auto z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                >
                    <input
                        className="w-[250px] sticky top-2 m-2 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        placeholder="Search"
                        onChange={(event) => setSearchInput(event.target.value)}
                    />
                    <div className="p-1">
                        {searchData?.length === 0
                            ? 'No Result'
                            : searchData?.map((search) => (
                                  <DropdownItem
                                      data={search}
                                      key={search.value}
                                      setValue={(value) => {
                                          setValue(value);
                                          setSearchInput('');
                                      }}
                                  />
                              ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

export default DropdownSearch;
