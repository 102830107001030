import React, { useEffect } from 'react';
import { fetchCurrencies, setCurrentCurrency } from '../../../store/slices/CurrencySlice';
import { useDispatch, useSelector } from 'react-redux';
import DropdownSearch from '../../../ui-component/DropdownSearch/DropdownSearch';

const Currency = () => {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language);
    const currency = useSelector((state) => state.currency);

    useEffect(() => {
        dispatch(fetchCurrencies({ idulng: language?.currenctLanguage?.idlanguage ?? 0 }));
    }, [language?.currenctLanguage]);

    return (
        <DropdownSearch
            data={currency.currencies.map((x) => ({
                value: x.iddefinition,
                name: `${x.name} ( ${x.symbol} )`
            }))}
            value={`${currency.currencies?.find((x) => x.iddefinition === currency?.currenctCurrency?.iddefinition)?.name} ( ${
                currency.currencies?.find((x) => x.iddefinition === currency?.currenctCurrency?.iddefinition)?.symbol
            } )`}
            setValue={({ value }) => {
                const selectedCurrency = currency.currencies.find((x) => x.iddefinition === value);
                dispatch(setCurrentCurrency(selectedCurrency));
            }}
        />
    );
};

export default Currency;
