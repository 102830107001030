import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { METHOD_TYPES } from '../enums/methodTypes';
import { RESOURCE_TYPES } from '../enums/resourceTypes';
import axiosFetch from '../../helpers/axiosFetch';

const initialState = {
    qr: '',
    productsGroupByCategory: {},
    formdata: {},
    products: [],
    categories: [],
    idfacility: '',
    name: '',
    idcurrency: 0,
    currencysymbol: '',
    currencysymbolcustomer: '',
    idcurrencycustomer: 0,
    idlanguage: 0,
    loading: false,
    error: '',
    method: ''
};

export const facilityQRMenuGet = createAsyncThunk('facilityQRMenuGet', async ({ qr, idlanguage, idcurrency }) => {
    const data = {
        qr,
        idlanguage,
        idcurrency
    };
    const response = await axiosFetch.post(RESOURCE_TYPES.FACILITYQRMENUGET, data);

    return response.data;
});

export const facilityQRMenuGetDetail = createAsyncThunk('facilityQRMenuGetDetail', async ({ qr, idlanguage, idproductmenu }) => {
    const data = {
        qr,
        idlanguage,
        idproductmenu
    };
    const response = await axiosFetch.post(RESOURCE_TYPES.FACILITYQRMENUGET_DETAIL, data);
    return response.data;
});
export const facilityMenuSlice = createSlice({
    name: 'facilityMenu',
    initialState,
    reducers: {
        resetFacilityMenuError(state) {
            state.error = '';
        },
        resetFacilityMenuMethod(state) {
            state.method = '';
        },
        setQr(state, { payload }) {
            state.qr = payload;
        }
    },
    extraReducers: (builder) => {
        //! Facility QR Menu Get
        builder.addCase(facilityQRMenuGet.pending, (state) => {
            state.loading = true;
            state.error = '';
            state.method = METHOD_TYPES.READ;
        });
        builder.addCase(facilityQRMenuGet.fulfilled, (state, { payload }) => {
            if (payload.status === false) {
                state.loading = false;
                state.error = payload.exception; // Error message
                return;
            }
            payload = payload.data;
            state.name = payload.name;
            state.idcurrency = payload.idcurrency;
            state.currencysymbol = payload.currencysymbol;
            state.idlanguage = payload.idlanguage;
            state.idfacility = payload.idfacility;
            state.products = payload.products;
            state.formdata = payload.formdata;
            state.currencysymbolcustomer = payload.currencysymbolcustomer;
            state.idcurrencycustomer = payload.idcurrencycustomer;

            const categoriesData = [];
            const groupedData = {};
            // Veriyi idcategory'ye göre gruplama
            payload.products?.forEach((item) => {
                const categories = item.idcategory;
                categories.forEach((category) => {
                    if (!groupedData[category]) {
                        groupedData[category] = [];
                        categoriesData.push(category);
                    }
                    groupedData[category].push(item);
                });
            });

            state.productsGroupByCategory = groupedData;
            state.categories = categoriesData;
            state.loading = false;
        });
        builder.addCase(facilityQRMenuGet.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload.exception; // Error message
        });
    }
});

// Action creators are generated for each case reducer function
export const { resetFacilityMenuError, resetFacilityMenuMethod, setQr } = facilityMenuSlice.actions;

export default facilityMenuSlice.reducer;
