import { useRouteError } from 'react-router-dom';

export default function ErrorPage() {
    const error = useRouteError();

    return (
        <div className="h-[100vh] flex flex-col justify-center text-center">
            <h1 className="font-bold text-6xl pb-4">Oops!</h1>
            <p className="py-2">Sorry, an unexpected error has occurred.</p>
            <p>
                <i className="text-xl">{error.statusText || error.message}</i>
            </p>
        </div>
    );
}
