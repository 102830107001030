import React from 'react';
import Product from './Product';
import Loading from '../../../layout/Loading';

const ProductList = ({ categories, products, currencysymbol, currencysymbolcustomer, loading }) => (
    <>
        {loading && <Loading />}
        <div className="flex flex-col p-2">
            {categories?.map((category) => (
                <div key={category} className="mt-8" id={category}>
                    <h1 className="font-semibold mb-4 ml-2">{category}</h1>
                    <div className="flex flex-col gap-2">
                        {products[category]?.map((item) => (
                            <Product
                                key={item.idproductmenu}
                                data={item}
                                currencysymbol={currencysymbol}
                                currencysymbolcustomer={currencysymbolcustomer}
                            />
                        ))}
                    </div>
                </div>
            ))}
        </div>
    </>
);

export default ProductList;
