import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setQr } from '../../store/slices/facilityMenuSlice';
import ProductList from './ProductList/ProductList';

function Home() {
    const { qrCode } = useParams();
    const dispatch = useDispatch();
    const facilityQrMenu = useSelector((state) => state.facilityQrMenu);

    const [products, setProducts] = useState({});

    useEffect(() => {
        if (qrCode) {
            dispatch(setQr(qrCode));
        }
    }, [qrCode]);

    useEffect(() => {
        if (facilityQrMenu.error) {
            throw new Error(facilityQrMenu.error);
        }
        setProducts(facilityQrMenu.productsGroupByCategory);
    }, [facilityQrMenu.productsGroupByCategory]);

    return (
        <>
            <ProductList
                categories={facilityQrMenu?.categories}
                products={products}
                currencysymbol={facilityQrMenu.currencysymbol}
                currencysymbolcustomer={facilityQrMenu.currencysymbolcustomer}
                loading={facilityQrMenu.loading}
            />
        </>
    );
}

export default Home;
